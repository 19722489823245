/* Default padding for larger screens */
.responsive-content {
    padding: 125px 230px 0px 230px;
    min-width: 100%;
    max-width: 100%;
    margin: 0 auto;
}
@media (min-width: 1600px) {
    .responsive-content {
        padding: 200px 450px 0px 450px ;
        min-width: 100%;
        max-width: 100%;
        margin: 0 auto;
    }
}
/* Padding for tablet screens */
@media (max-width: 1024px) {
    .responsive-content {
        padding: 100px 30px;
    }
}

/* Padding for mobile screens */
@media (max-width: 768px) {
    .responsive-content {
        padding: 100px 20px;
    }
}

/* Padding for very small screens */
@media (max-width: 480px) {
    .responsive-content {
        padding: 100px 15px;
    }
}
