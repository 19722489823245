/* src/HomePage.css */

.homepage-container {
  position: relative;
  height: 100vh;
  width: 100%;
  background-image: url('../img/background.jpg');
  /* Ensure the path is correct */
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
/* Mobile background for screens smaller than 768px */
@media (max-width: 768px) {
  .homepage-container {
    position: relative;
    height: 100vh;
    width: 100%;
    background-image: url('../img/bg_mbl.jpg');
    /* Ensure the path is correct */
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
}
.homepage-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity for darkness */
  z-index: 1;
}
.footer-menu .ant-menu-item-active,
.footer-menu .ant-menu-item-selected {
  background-color: #f0f0f0;
  /* A light gray background */
}

/* Change the text color to black when the menu item is active */
.footer-menu .ant-menu-item :hover .footer-menu .ant-menu-item-active a,
.footer-menu .ant-menu-item-selected a {
  color: black !important;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
  text-align: center;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}

.custom-divider {
  width: 50%;
  margin: 0 auto;
}


/* Initial button state - white text and white border */


.header {
  background-color: #0f0f0f;
}

.ant-card .ant-card-body{
  padding: 0px !important;
}
/* 
.custom-divider {
  width: 100%;
  height: 2px; 
  background: url('../img/bird.png') no-repeat right center; 
  background-size: contain;
  margin: 20px 0;
  color: #ffffff;
} */
 /* Show the mobile menu button on screens smaller than 768px */
@media (max-width: 768px) {
  .mobile-menu-button {
    display: inline-block;
  }
  .mobile-menu-drawer .ant-menu-inline {
    border-right: none;
  }
}

/* Hide the default menu on screens smaller than 768px */
@media (max-width: 768px) {
  .ant-menu-horizontal {
    display: none;
  }
}