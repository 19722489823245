/* Custom styles for the responsive menu */
.custom-responsive-menu {
  background-color: transparent;
}

/* Default text color for menu items */
.custom-responsive-menu .ant-menu-item a {
  color: #ffffff; /* White text color */
}

/* Hover effect for menu items */
.custom-responsive-menu .ant-menu-item:hover a {
  color: #FFD700; /* Golden text color on hover */
}

/* Background color on hover */
.custom-responsive-menu .ant-menu-item:hover {
  background-color: #444444; /* Darker background color on hover for better contrast */
}

/* Submenu background color */
.custom-responsive-menu .ant-menu-submenu-popup {
  background-color: #333333; /* Very dark ash background color */
}

/* Submenu item text color */
.custom-responsive-menu .ant-menu-submenu-title {
  color: #ffffff; /* White text color for submenu title */
}

/* Selected menu item text color for main menu and submenu */
.custom-responsive-menu .ant-menu-item-selected a,
.custom-responsive-menu .ant-menu-submenu-selected a {
  color: #FFD700 !important; /* Golden text color for selected item */
}

/* Focus styles for selected menu items */
/* .custom-responsive-menu .ant-menu-item-selected,
.custom-responsive-menu .ant-menu-submenu-selected {

  
} */
.custom-responsive-menu .ant-menu-item-selected,
.custom-responsive-menu .ant-menu-submenu-selected {
  background-color: transparent !important; /* Remove any background color */
}

/* Drawer background color */
.ant-drawer-body {
  background-color: #1a1a1a; /* Very dark ash background color */
}

.ant-menu::before {
  content: '';
}